<template>
  <div class="md:bg-purple-300 h-screen">
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div class="h-screen md:flex items-center justify-center hidden">
        <div class="flex flex-col h-full">
          <div class="py-4 px-10">
            <router-link :to="{ name: 'home' }">
              <h1
                class="
                  hidden
                  md:flex
                  text-white
                  hover:text-gray-700
                  font-extrabold
                  text-base
                  md:text-2xl
                "
                style="font-family: 'Gluten', cursive"
              >
                putzfome
              </h1>
            </router-link>
          </div>
          <div class="hidden items-center justify-center md:flex h-full w-full">
            <div class="lg:w-1/2">
              <img src="@/assets/images/take-away-pana2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:h-auto h-screen">
        <div class="bg-white w-full p-2 flex flex-1">
          <div class="w-full px-6 md:px-8 lg:w-9/12 mx-auto flex items-center">
            <div class="w-full space-y-8" v-if="!resendCode">
              <div class="md:hidden">
                <router-link :to="{ name: 'home' }">
                  
                  <h1
                    class="
                      text-center text-gray-800
                      hover:text-gray-700
                      font-extrabold
                      text-2xl
                    "
                    style="font-family: 'Gluten', cursive"
                  >
                    putzfome
                  </h1>
                </router-link>
              </div>
              <div>
                <h2
                  class="
                    mt-6
                    text-lg
                    md:text-lg
                    font-normal
                    text-gray-700 text-center
                  "
                >
                  Digite o código que enviamos para seu e-mail
                </h2>
                <span class="font-semibold text-center block">{{
                  formData.email
                }}</span>
              </div>
              <form @submit.prevent="validateToken" class="mt-8 space-y-6">
                <div class="rounded-md shadow-sm space-y-6">
                  <div class="">
                    <input
                      id="token"
                      name="token"
                      type="text"
                      v-model="formData.token"
                      autocomplete="token"
                      placeholder="Digite código de verificação"
                      :class="{ 'border border-red-600 ': hasError('token') }"
                      class="
                        block
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                        transition
                        duration-500
                        ease-in-out
                        transform
                        border border-transparent
                        rounded-lg
                        bg-gray-50
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                        text-gray-600
                      "
                    />
                    <div
                      class="text-red-600 p-1"
                      :class="{
                        'bg-red-50': hasError('token'),
                      }"
                    >
                      {{ getError("token") }}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="
                      group
                      relative
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-500
                      hover:bg-indigo-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    :disabled="loading"
                  >
                    <span
                      class="absolute left-0 inset-y-0 flex items-center pl-3"
                    >
                      <!-- Heroicon name: solid/lock-closed -->
                      <svg
                        class="
                          h-5
                          w-5
                          text-indigo-500
                          group-hover:text-indigo-400
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span v-if="loading" class="flex items-center">
                      <img
                        src="@/assets/images/preloader.gif"
                        alt=""
                        class="w-5 h-5 mr-2"
                      />
                      aguarde um momento
                    </span>
                    <span v-else>Continuar</span>
                  </button>
                </div>
              </form>

              <button class="text-red-600 text-right w-full" @click="noCode">
                Não recebi meu código
              </button>
            </div>

            <div class="flex flex-col space-y-5" v-if="resendCode">
              <h2 class="font-semibold text-gray-800 text-center text-2xl">
                Não recebeu o código?
              </h2>
              <p class="text-gray-600">
                Verifique se digitou o e-mail corretamente. Talvez o código
                esteja na sua caixa de spam.
              </p>
              <div class="rounded-md shadow-sm space-y-6">
                <div class="">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    v-model="formData.email"
                    autocomplete="email"
                    placeholder="Digite seu e-mail"
                    class="
                      block
                      w-full
                      px-5
                      py-3
                      text-base text-neutral-600
                      placeholder-gray-300
                      transition
                      duration-500
                      ease-in-out
                      transform
                      border border-transparent
                      rounded-lg
                      bg-gray-50
                      focus:outline-none
                      focus:border-transparent
                      focus:ring-2
                      focus:ring-white
                      focus:ring-offset-2
                      focus:ring-offset-gray-300
                      text-gray-600
                    "
                    :class="{ 'border border-red-600 ': hasError('email') }"
                  />

                  <div class="text-red-600">
                    {{ getError("email") }}
                  </div>
                </div>
              </div>

              <button
                @click="resendCodeEmail"
                type="submit"
                class="
                  group
                  relative
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-indigo-500
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                Reenviar código
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      resendCode: false,

      formData: {
        email: null,
        token: "",
      },
      errors: null,
    };
  },
  created() {
    this.formData.email = this.$route.params.email;
  },
  methods: {
    ...mapActions(["validateResetCode", "forgotPassword"]),
    validateToken() {
      this.reset();
      this.loading = true;

      this.validateResetCode(this.formData)
        .then((response) => {
          this.$router.push({
            name: "reset.password",
            params: { email: this.formData.email },
          });
        })

        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Dados invalídos",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.errors = null;

    },

    noCode() {
      this.resendCode = !this.resendCode;
       this.errors = null;
       this.formData.token = null
    },

    resendCodeEmail() {
      this.forgotPassword(this.formData)
        .then((response) => {
          this.resendCode = !this.resendCode;
       
        })
        .catch((error) => {
   
          this.errors = error.response.data.errors;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Dados invalídos",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    hasError(field) {
      if (this.errors) {
        return this.errors[field];
      }
    },
    getError(field) {
      if (this.hasError(field)) {
        return this.errors[field][0];
      }
    },
  },
};
</script>